<template>
  <div class="contain" v-title data-title="菜乐购">
    <van-button type="primary" block @click="toDetail">商品详情</van-button>
    <van-button type="primary" block style="margin-top: 20px;" @click="toDetail2">后台商品详情</van-button>
    <van-button type="primary" block style="margin-top: 20px;" @click="toConfirm">确认订单</van-button>
    <van-button type="primary" block style="margin-top: 20px;" @click="toThPay">支付跳转</van-button>
    <van-button type="primary" block style="margin-top: 20px;" @click="toOrdQrCodePay">支付跳转2</van-button>
    <van-button type="primary" block style="margin-top: 20px;" @click="toRule">菜乐购隐私条款</van-button>
    <van-button type="primary" block style="margin-top: 20px;" @click="toR">t支付</van-button>
  </div>
</template>

<script>

export default {
  name: 'Home',
  mounted () {
    console.log(process.env.NODE_ENV)
  },
  methods: {
    toDetail () {
      this.$router.push({
        path: '/goodsDetail'
      })
    },
    toDetail2 () {
      this.$router.push({
        path: '/backGoodsDetail'
      })
    },
    toConfirm () {
      this.$router.push({
        path: '/confirmOrder'
      })
    },
    toThPay () {
      this.$router.push({
        path: '/thPay'
      })
    },
    toOrdQrCodePay () {
      this.$router.push({
        path: '/ordQrCodePay'
      })
    },
    toRule () {
      this.$router.push({
        path: '/clgPlusRule'
      })
    },
    toR () {
      this.$router.push({
        path: '/tPay'
      })
    }
  }
}
</script>
<style scoped lang="scss">
.contain {
  margin: 10px;
}

</style>
